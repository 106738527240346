<template>
  <div class="wrapper">
    <admin-title :title="$route.params.id ? '编辑管理员' : '添加管理员'"></admin-title>
    <a-divider />

    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item prop="userName" label="用户名">
        <el-input v-model="formData.userName" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item v-if="(!$route.params.id)" label="密码" prop="password">
        <el-input v-model="formData.password" placeholder="密码"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="formData.mobile" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item prop="roleId" label="角色">
        <el-select v-model="formData.roleId" clearable placeholder="请选择">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-switch v-model="formData.status" :active-value="1" :inactive-value="0" active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
      </el-form-item> -->
      <el-form-item label=" ">
        <el-button type="" @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAdmin, editAdmin, getAdminDetail, getRoleList } from './api'
export default {
  name: 'Detail',

  data() {
    return {
      formData: {
        userName: '',
        mobile: '',
        password: '',
        roleId: null
      },
      rules: {
        userName: [
          { required: true, message: "姓名不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { pattern:/^(?=.*[A-Z])(?=.*[0-9])[A-Z|a-z|0-9|_]{8,18}$/, message: '密码为8~18位，可包含大小写字母、数字和下划线，且至少包含大写字母+数字', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" }
        ],
        roleId: [
          { required: true, message: "请选择角色", trigger: "blur" }
        ]
      },
      roleList: [],
      roleProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        emitPath: false,
        multiple: true
      }
    }
  },

  mounted() {
    this.getRoleList()
    if (this.$route.params.id) this.getAdminDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editAdmin(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            await addAdmin(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getAdminDetail() {
      this.formData = await getAdminDetail({ id: this.$route.params.id })
    },
    cancel() {
      this.$router.back(-1)
    },
    async getRoleList() {
      const res = await getRoleList({ pageSize: 50, page: 1 })
      this.roleList = res.list || []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 70%;
        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
